<template>
  <!-- 已邮寄批次页面 -->
  <div class="box">
    <!-- 搜索部分 -->
    <div style="display: flex; margin-bottom: 35px">
      <div class="condition-item">
        <label class="fn-14">批次名称</label>
        <el-input
          clearable
          v-model="searchBatchName"
          placeholder="请输入批次名称"
          autocomplete="off"
          size="small"
          style="width: 160px"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">摄影师</label>
        <el-input
          clearable
          v-model="searchPhotographer"
          placeholder="请输入摄影师"
          autocomplete="off"
          size="small"
          style="width: 160px"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">创建时间</label>
        <el-date-picker
          clearable
          v-model="searchBeginTime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker
          clearable
          align="right"
          type="date"
          v-model="searchEndTime"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
          autocomplete="off"
          size="small"
          style="width: 160px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          size="small"
          style="width: 85px"
          @click="searchList"
          v-if="searchButton"
          >搜 索</el-button
        >
      </div>
    </div>
    <!-- 列表盒子 -->

    <div style="display: flex; flex-direction: column; height: calc(100vh - 340px)">
      <el-table
        border
        ref="multipleTable"
        :data="mailedList"
        style="width: 100%; text-align: center"
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <el-table-column align="center" fixed type="selection" width="50">
        </el-table-column>
        <el-table-column prop="title" fixed label="批次名称" width="260">
        </el-table-column>
        <el-table-column align="center" prop="senderName" label="联系人" width="120">
        </el-table-column>
        <el-table-column align="center" prop="phone" label="联系人电话" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span></template
          >
        </el-table-column>

        <el-table-column align="center" prop="createTime" label="创建时间" width="120">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="photographer" label="摄影师" width="120">
        </el-table-column>
        <el-table-column align="center" prop="totalCount" label="采集人数" width="120">
        </el-table-column>
        <el-table-column align="center" prop="mailGroup" label="邮寄分组" width="150">
          <template slot-scope="scope">
            <span v-html="scope.row.mailGroup"></span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="mailCount" label="邮寄数" width="100">
        </el-table-column>
        <el-table-column
          align="center"
          prop="mailStatus"
          label="邮寄状态"
          min-width="120"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.mailStatus === 1"
              type="warning"
              effect="plain"
              size="small"
              >待生成邮寄单号</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 2"
              type="primary"
              effect="plain"
              size="small"
              >正在生成邮寄单号
            </el-tag>
            <el-tag
              v-else-if="scope.row.mailStatus === 3"
              type="warning"
              effect="plain"
              size="warning"
              >待打印面单</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 4"
              type="warning"
              effect="plain"
              size="small"
              >待确认邮寄</el-tag
            >

            <el-tag
              v-else-if="scope.row.mailStatus === 5"
              type="primary"
              effect="plain"
              size="small"
              >正在提交邮寄</el-tag
            >
            <el-tag
              v-else-if="scope.row.mailStatus === 6"
              type="success"
              effect="plain"
              size="small"
              >已邮寄</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" min-width="260"> </el-table-column>

        <el-table-column align="center" prop="address" label="操作" width="90">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="viewdetails(scope.row)"
              v-if="viewdetailsButton"
              >查看明细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div style="display: flex; justify-content: center; padding: 10px 0px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        style="display: flex; justify-content: center; flex-direction: row"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 查看弹窗 -->
    <div>
      <el-dialog
        title="查看明细"
        :visible.sync="dialogVisible"
        width="1500px"
        class="ERER"
      >
        <!-- 搜索部分 -->
        <div style="display: flex; margin-bottom: 20px">
          <div class="condition-item">
            <label class="fn-14">拍照序号</label>
            <el-input
              clearable
              v-model="viewdetailSearch.searchIndexNumber"
              placeholder="请输入拍照序号"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">姓名</label>
            <el-input
              clearable
              v-model="viewdetailSearch.searchIdName"
              placeholder="请输入姓名"
              autocomplete="off"
              size="small"
            ></el-input>
          </div>
          <div class="condition-item">
            <label class="fn-14">身份证号</label>
            <el-input
              clearable
              v-model="viewdetailSearch.searchIdNumber"
              placeholder="请输入身份证号"
              autocomplete="off"
              size="small"
              width="220"
            ></el-input>
          </div>
          <div class="condition-item">
            <el-button
              type="primary"
              size="small"
              style="width: 85px"
              @click="searchquery"
              >查询</el-button
            >
          </div>
        </div>
        <!-- 弹窗列表盒子 -->
        <div>
          <el-table
            border
            :data="mailedListInfo"
            ref="multipleTable"
            style="width: 100%; text-align: center"
            height="400"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          >
            <el-table-column
              align="center"
              prop="batchIndex"
              label="拍照序号"
              width="80"
              fixed
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="mailGroup"
              label="邮寄分组"
              width="150"
              fixed
            >
            </el-table-column>
            <el-table-column align="center" prop="idNumber" label="身份证号" width="240">
              <template slot-scope="scope">
                <span>{{ execDecrypt(scope.row.idNumber, "Idcard") }}</span></template
              >
            </el-table-column>
            <el-table-column align="center" prop="name" label="姓名" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span></template
              >
            </el-table-column>
            <el-table-column
              align="center"
              prop="collectedTime"
              label="采集时间"
              width="240"
            >
              <template slot-scope="scope">
                {{ formDateFormat(scope.row.collectedTime) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="schoolName" label="学校" width="220">
            </el-table-column>
            <el-table-column align="center" prop="mailNo" label="快递单号" width="220">
            </el-table-column>
            <el-table-column
              align="center"
              prop="mailStatus"
              label="邮寄状态"
              width="200"
            >
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.mailStatus === 1"
                  type="warning"
                  effect="plain"
                  size="small"
                  >待生成邮寄单号</el-tag
                >
                <el-tag
                  v-else-if="scope.row.mailStatus === 2"
                  type="success"
                  effect="plain"
                  size="small"
                  >正在生成邮寄单号
                </el-tag>
                <el-tag
                  v-else-if="scope.row.mailStatus === 3"
                  type=""
                  effect="plain"
                  size="small"
                  >待打印面单</el-tag
                >
                <el-tag
                  v-else-if="scope.row.mailStatus === 4"
                  type=""
                  effect="plain"
                  size="small"
                  >待确认邮寄</el-tag
                >

                <el-tag
                  v-else-if="scope.row.mailStatus === 5"
                  type=""
                  effect="plain"
                  size="small"
                  >正在提交邮寄</el-tag
                >
                <el-tag
                  v-else-if="scope.row.mailStatus === 6"
                  type=""
                  effect="plain"
                  size="small"
                  >已邮寄</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="display: flex; justify-content: center; padding-top: 15px">
          <el-pagination
            @size-change="handleSizeChangeview"
            @current-change="handleCurrentChangeview"
            :current-page="currentPageView"
            style="display: flex; justify-content: center; flex-direction: row"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSizeView"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalView"
          >
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mailedList, mailedListInfo } from "@/api/mail";
import { dateFormat } from "@/utils/date";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import { queryPageButton } from "@/api/permission";

export default {
  name: "mailMailed",

  data() {
    return {
      // 列表
      dialogVisible: false,
      mailedList: [],
      searchPhotographer: "",
      searchBatchName: "",
      searchBeginTime: null,
      searchEndTime: null,
      currentPage: 1,
      pageSize: 20,
      total: 0,
      //明细
      mailedListInfo: [],
      viewdetailSearch: {
        searchIndexNumber: "",
        searchIdNumber: "",
        searchIdName: "",
      },
      currentPageView: 1,
      pageSizeView: 20,
      totalView: 0,
      collectType: null,
      //权限按钮
      searchButton: false,
      viewdetailsButton: false,
    };
  },
  created() {
    this.queryButton();
  },

  methods: {
    queryButton() {
      const data = 125;
      queryPageButton(data).then((resp) => {
        // console.log(resp);
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "viewdetailsButton") {
            this.viewdetailsButton = true;
          }
        }
      });
    },
    /**
     * 时间格式化
     *
     * **/
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    // 列表数据
    querList() {
      const datas = {
        photographer: this.searchPhotographer,
        batchName: this.searchBatchName,
        beginTime: this.formDateFormat(this.searchBeginTime),
        endTime: this.formDateFormat(this.searchEndTime),
      };
      mailedList(datas, this.currentPage, this.pageSize).then((resp) => {
        // console.log( resp);
        if (resp.code == 0) {
          let datas = resp.data.content;
          this.mailedList = datas;
          this.total = resp.data.totalElements;
        }
      });
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.querList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querList();
    },
    // 列表搜索
    searchList() {
      this.querList();
    },

    // 查看明细
    viewdetails(data) {
      this.detailedID = data.id;
      this.collectType = data.collectType;
      this.mailedListInfo=[]
      this.viewdetailSearch={}
      // console.log(this.detailedID);
      this.viewdetailsList();
    },
    // 明细列表
    viewdetailsList() {
      

      const postData = {
        id: this.detailedID,
        collectType: this.collectType,
        batchIndex: this.viewdetailSearch.searchIndexNumber,
        idNumber: this.viewdetailSearch.searchIdNumber,
        name: this.viewdetailSearch.searchIdName,
      };

      mailedListInfo(postData, this.currentPageView, this.pageSizeView).then((resp) => {
        if (resp.code == 0) {
          // console.log(resp);
          let dataview = resp.data.content;
          this.mailedListInfo = dataview;
          this.totalView = resp.data.totalElements;
          this.dialogVisible = true;

        }
      });
    },
    // 查看明细列表分页
    handleSizeChangeview(val) {
      this.pageSizeView = val;
      this.viewdetailsList();
    },
    handleCurrentChangeview(val) {
      this.currentPageView = val;
      this.viewdetailsList();
    },
    // 弹窗查询
    searchquery() {
      this.viewdetailsList();
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "ph") {
        return formatPh(str);
      } else if (mod == "name") {
        return formatName(str);
      } else if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
  mounted() {
    this.querList();
  },
};
</script>

<style scoped>
.el-checkbox__inner {
  border-radius: 20px !important;
}
/* 查看按钮 */
.d-btn {
  width: 100px;
  height: 30px;
  text-align: center !important;
  line-height: 5px;
}
</style>
